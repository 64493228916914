import HttpRequest from "@/components/httpRequest";
import { validateEMail } from "@/components/utils";
import _isEmpty from "lodash/isEmpty";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import useAppInfo from "../app/use-app-info";
import useCompanyGroupListUnit from "../company-group/use-company-group-list-unit";
import _uniqueId from "lodash/uniqueId";
import useCurrencyListUnit from "../currency/use-currency-list-unit";

const REQUIRED_MESSAGE = "Zorunlu alan";

const useCompanyUnit = create(
  immer((set, get) => ({
    company: {},
    groups: undefined,
    extraInfo: undefined,
    errors: {},
    validationWasMade: false,

    changeCompanyInfo(values) {
      set((state) => {
        state.company = { ...state.company, ...values };
      });

      const { validate, validationWasMade } = get();
      if (validationWasMade) validate();
    },

    changeState(values) {
      set((state) => {
        return { ...state, ...values };
      });
    },

    validate() {
      const errors = getErrors(get().company);
      set((state) => {
        state.errors = errors;
        state.validationWasMade = true;
      });
      return _isEmpty(errors);
    },

    initialize(companyId) {
      return companyId ? loadCompany(companyId) : loadNew();

      function loadNew() {
        const companyGroups = useCompanyGroupListUnit.getState().companyGroups;
        const activeCurrencies =
          useCurrencyListUnit.getState().activeCurrencies;

        const groups = companyGroups
          .filter((g) => g.defaultValue)
          .map((g) => g.groupId);

        const balances = activeCurrencies.map((c) => ({
          currencyId: c.currencyId,
          currencyName: c.currencyName,
          balances: 0,
        }));

        set((state) => {
          state.company = {};
          state.groups = groups;
          state.extraInfo = generateExtraInfoRows(3);
          state.balances = balances;
          state.errors = {};
          state.validationWasMade = false;
          state.originals = undefined;
        });
      }

      async function loadCompany(companyId) {
        const { company, groups, extraInfo, balances } =
          await fetchCompany(companyId);
        set((state) => {
          state.company = company;
          state.groups = groups;
          state.extraInfo = extraInfo.map((info) => ({
            ...info,
            saved: true,
            originalRowNumber: info.rowNumber,
          }));
          state.originals = { company, groups: JSON.stringify(groups) };
          state.balances = balances;
        });
      }
    },

    createCompany() {
      const { company, groups, extraInfo, balances } = get();

      const newExtraInfo = extraInfo
        .filter((info) => info.label || info.value)
        .map((info, rowNumber) => ({ ...info, infoId: undefined, rowNumber }));

      const postData = {
        ...company,
        groups,
        extraInfo: newExtraInfo,
        openingBalances: balances,
      };

      return postCompany(postData);
    },

    updateCompany() {
      const { originals, company, groups, extraInfo } = get();
      const changes = [];
      checkChange("companyName");
      checkChange("phoneNumber");
      checkChange("eMail");
      checkChange("address");
      checkChange("taxOffice");
      checkChange("taxNumber");

      if (originals.groups !== JSON.stringify(groups)) changes.push("groups");
      const newExtraInfo = prepareExtraInfo(extraInfo);
      if (isExtraInfoChanged(newExtraInfo)) changes.push("extraInfo");

      const putData = {
        ...company,
        groups,
        extraInfo: changes.includes("extraInfo") ? newExtraInfo : undefined,
        changes,
      };
      if (changes.length === 0) return Promise.resolve();
      return putCompany(putData);

      function checkChange(fieldName) {
        if (originals.company[fieldName] !== company[fieldName]) {
          changes.push(fieldName);
        }
      }

      function prepareExtraInfo(extraInfo) {
        let rowNumber = 0;

        return extraInfo
          .filter((info) => info.label || info.value || info.saved)
          .map((info) => {
            const deleted = info.deleted || (!info.label && !info.value);
            const newRowNumber = deleted ? 0 : rowNumber++;

            const rowNumberChanged =
              info.saved &&
              !info.changed &&
              !deleted &&
              info.originalRowNumber !== newRowNumber;

            return {
              infoId: info.infoId,
              label: info.label,
              value: info.value,
              new: !info.saved,
              changed: info.changed,
              deleted,
              rowNumber: newRowNumber,
              rowNumberChanged,
            };
          });
      }

      function isExtraInfoChanged(extraInfo) {
        return extraInfo.some(
          (info) =>
            info.changed || info.deleted || info.new || info.rowNumberChanged,
        );
      }
    },
  })),
);

const getErrors = (company) => {
  const errors = {};
  if (!company.companyName) errors.companyName = REQUIRED_MESSAGE;
  if (company.eMail && !validateEMail(company.eMail))
    errors.eMail = "Geçersiz e-posta adresi";
  return errors;
};

export default useCompanyUnit;

function postCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-company")
    .addHeader("authorization", appToken)
    .postAsJson(company);
}

function putCompany(company) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/update-company")
    .addHeader("authorization", appToken)
    .putAsJson(company);
}

function fetchCompany(companyId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/get-company/${companyId}`)
    .addHeader("authorization", appToken)
    .get();
}

function generateExtraInfoRows(count) {
  const r = [];
  for (let i = 0; i < count; i++) {
    r.push({ infoId: _uniqueId("-"), label: "", value: "" });
  }
  return r;
}

const mockBalances = [
  {
    currencyId: 1,
    currencyName: "Türk Lirası",
    balance: 120,
    tryEquivalent: 120,
  },
  {
    currencyId: 2,
    currencyName: "ABD Doları",
    balance: 150,
    tryEquivalent: 4677.85,
  },
  {
    currencyId: 3,
    currencyName: "Euro",
    balance: 130.5,
    tryEquivalent: 4403.09,
  },
];
