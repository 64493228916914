import { delay } from "./utils";

export default class HttpRequest {
  constructor(baseUrl, path) {
    this.url = baseUrl + path;
    this.headers = {};
  }

  addHeader(headerName, headerValue) {
    this.headers[headerName] = headerValue;
    return this;
  }

  async get() {
    const requestInfo = {
      headers: this.headers,
      // credentials: "include",
      method: "get",
    };
    return await this.fetchAndGetData(requestInfo);
  }

  async post() {
    const requestInfo = {
      headers: { ...this.headers },
      method: "post",
    };
    return await this.fetchAndGetData(requestInfo);
  }
  async postAsJson(data) {
    const requestInfo = {
      headers: {
        ...this.headers,
        "content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
      // credentials: "include",
      method: "post",
      body: JSON.stringify(data),
    };
    return await this.fetchAndGetData(requestInfo);
  }

  async postAsForm(data) {
    const requestInfo = {
      headers: {
        ...this.headers,
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      credentials: "include",
      method: "post",
      body: new URLSearchParams(data),
    };
    return await this.fetchAndGetData(requestInfo);
  }

  async putAsJson(data) {
    const requestInfo = {
      headers: {
        ...this.headers,
        "content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
      // credentials: "include",
      method: "put",
      body: JSON.stringify(data),
    };
    return await this.fetchAndGetData(requestInfo);
  }

  async delete() {
    const requestInfo = {
      headers: this.headers,
      method: "delete",
    };
    return await this.fetchAndGetData(requestInfo);
  }

  async fetchAndGetData(requestInfo) {
    try {
      if (window.__error) {
        return Promise.reject("unspecified");
      } ///! ! !
      if (window.__wait) {
        await delay(window.__wait);
      }
      const response = await fetch(this.url, requestInfo);

      switch (response.status) {
        case 200:
          const data = Promise.resolve(await response.json());
          return data;

        case 204:
          return Promise.resolve();

        case 304:
          return Promise.resolve({ notModified: true });

        case 401:
          return Promise.reject("unauthorized");

        case 403:
          return Promise.reject((await getErrorId(response)) || "forbidden");

        case 404:
          return Promise.reject("notfound");

        case 409:
          return Promise.reject((await getErrorId(response)) || "conflict");

        default:
          return Promise.reject("unspecified");
      }
    } catch (error) {
      return Promise.reject("unspecified");
    }
  }
}

async function getErrorId(response) {
  const text = await response.text();
  if (!text) return;
  try {
    return JSON.parse(text);
  } catch {
    return text;
  }
}
