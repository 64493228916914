import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/app/playground/devpanel/hidden-dev-panel.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/src/components/app-messenger/app-messenger.jsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/dropdown-menu/dropdown-menu-styles.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/shake.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/components/slim-loading/slim-loading.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/react-data-grid/lib/styles.css");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/app/globals.css");
